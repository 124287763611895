<template>
  <div>
    <beschreibung-gross v-if="!isMobile" />
    <beschreibung-mobil v-if="isMobile" />
  </div>
</template>

<script>
  import fieldMixin from "./../mixins/fieldMixin";
  import baseMixin from "./../mixins/baseMixin";
  import BeschreibungGross from "./BeschreibungGross.vue";
  import BeschreibungMobil from "./BeschreibungMobil.vue";
  export default {
    name: "index-home",
    components: {
      BeschreibungGross,
      BeschreibungMobil,
    },
    mixins: [fieldMixin, baseMixin],
    data: function() {
      return {};
    },
    created() {},

    methods: {},
  };
</script>
