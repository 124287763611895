<template>
  <div class="pl-4 mt-4">
    <v-row>
      <v-col cols="12" md="9" xs="12">
        <v-row>
          <v-col cols="12" md="9" class="mt-4 pt-0">
            <h2 class="mb-0 pb-0">Hunde-Krankenversicherung</h2>
            <h1 class="mt-0 pt-0">
              <span class="redtext" style="font-size:36px;">{{
                tarif.tier_title
              }}</span>
            </h1>

            Die Hunde-Krankenversicherung {{ tarif.tier_title }} hat eine
            Durchschnittserstattung von
            <b class="redtext"> {{ tarif.tier_wert }}% </b>. Basis ist für die
            Ermittlung der Durchschnittserstattung ist die Eisbaum Realwert
            Methode (<a @click="showRechenweg = !showRechenweg"
              >Realwert Berechnung anzeigen</a
            >). <br />
            <div class="my-2" v-if="tarif.un_comment != ''">
              <b>Hinweis:</b> {{ tarif.un_comment }}
            </div>
            <v-row v-if="tarif.tier_vergleich_nein > 0" class="my-4">
              <v-col cols="12" md="12">
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  color="red"
                  elevation="2"
                >
                  <h2 v-if="tarif.tier_vergleich_status == 1">
                    Der Tarif wird gerade analysiert
                  </h2>
                  <h2 v-if="tarif.tier_vergleich_status == 2">
                    Versicherung arbeitet nicht mit Vergleichsportalen
                  </h2>
                  <h2 v-if="tarif.tier_vergleich_status == 3">
                    Tarif wurde von der Versicherung geschlossen
                  </h2>

                  Aus diesem Grund wurden die Tarifinformationen bisher noch
                  nicht von der Versicherung bestätigt bzw. Fragen zum Tarif von
                  der Versicherung beantwortet. Die Tarifinformationen basieren
                  lediglich auf unserer Auswertung der zugänglichen
                  Tarifunterlagen. Der Tarif wird nicht in unserem Online
                  Rechner berücksichtigt, weil wir keine Verantwortung für die
                  Vollständigkeit, Richtigkeit oder Aktualität der Informationen
                  übernehmen können.
                </v-alert>
              </v-col>
            </v-row>
            <!-- !{{ tarif.un_abschluss_url }} -->
            <v-btn
              v-if="tarif.un_abschluss_url != ''"
              class="nocaps my-4"
              style="background-color: #d84315; color: #fff"
            >
              <a
                @click="saveAntragClickend()"
                :href="tarif.un_abschluss_url"
                target="_blank"
                style="color: #fff"
                >jetzt {{ tarif.tier_title }} online beantragen
              </a></v-btn
            >
          </v-col>
          <v-col cols="12" md="3">
            <div
              style="border-left:2px solid #d84315;background-color:#f2f2f2"
              class="pa-4"
            >
              <span class="redtext" style="font-size:64px;"
                >{{ tarif.tier_wert }}%
              </span>
              <br />Eisbaum Realwert
            </div>
          </v-col>
        </v-row>

        <br />

        <v-tabs style="width: 888px">
          <v-tab class="nocaps">Leistungsbeschreibung</v-tab>
          <v-tab class="nocaps">Eisbaum Testverfahren</v-tab>
          <v-tab class="nocaps" v-if="tarif.tier_vergleich_nein == 0"
            >Beitragstabelle</v-tab
          >

          <v-tab
            class="nocaps"
            v-if="beispiele.length > 0 && tarif.tier_vergleich_nein == 0"
            >Erstattungsbeispiele</v-tab
          >
          <v-tab
            class="nocaps"
            v-if="avb.length > 0 && tarif.tier_vergleich_nein == 0"
            >Downloads</v-tab
          >

          <v-tab-item class="mt-4 pl-3">
            <v-row v-if="tarif.tier_sb_art == 3">
              <v-col cols="12" md="12"
                ><b>Dieser Tarif hat eine Selbstbeteiligung:</b><br />
                Die Selbstbeteiligung beträgt {{ tarif.tier_sb_prozent }} % des
                Rechnungsbetrages, maximal {{ tarif.tier_sb_euro }} € für alle
                zeitgleich zusammen eingereichten Rechnungen eines
                Abrechnungsjahres. Sparen Sie Geld, indem Sie die Rechnungen für
                Behandlungen, die in einem Abrechnungsjahr durchgeführt wurden,
                gesammelt in einem Vorgang zur Abrechnung einreichen. Achtung:
                Reichen Sie jede Rechnung einzeln ein, tragen Sie für jeden
                Rechnungsbetrag {{ tarif.tier_sb_prozent }} % Selbstbeteiligung.
              </v-col>
            </v-row>
            <v-row
              v-for="item in origin"
              :key="item.value"
              class="mb-2"
              style="width: 888px;"
            >
              <v-col
                cols="12"
                md="12"
                v-if="!item.showintern && tarif[item.value]"
              >
                <v-row style="border-bottom:1px solid #f2f2f2">
                  <v-col cols="12" md="3" xs="12">
                    {{ item.title }}
                    <!-- <br />{{ item.value }} | {{ item.type }}--></v-col
                  >
                  <v-col cols="12" md="9" xs="12">
                    <b
                      ><span class="pb-0 mb-0">
                        {{ showFieldComputed(item.value, "hund") }}</span
                      ></b
                    >
                    <a
                      v-if="item.spezialpop && tarif.tier_vergleich_nein == 0"
                      @click="item.show_spezial_pop = !item.show_spezial_pop"
                      >&nbsp;mehr Infos...</a
                    >
                    <v-dialog
                      v-if="item.spezialpop"
                      v-model="item.show_spezial_pop"
                      width="888"
                    >
                      <spezial-pop
                        :item="tarif"
                        :type="item.spezialpop"
                      ></spezial-pop>
                    </v-dialog>
                    <ButtonTip
                      v-if="item.popup && !item.spezialpop"
                      cssstyle="
                 
                
                  cursor: pointer;
                "
                      text="Info"
                      icon="mdi-delete"
                      :tooltip="item.value"
                      eventname="deleterec"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="mt-4 pl-3"
            ><index-rechenweg :tierart="1" :tarif="tarif" />
          </v-tab-item>
          <v-tab-item class="mt-4 pl-3" v-if="tarif.tier_vergleich_nein == 0"
            >Dies ist der Preis für die Krankenversicherung eines
            Cocker-Spaniels bei der {{ tarif.tier_title }}. Je jünger der Hund
            beim Versicherungsabschluss ist desto günstiger ist der monatliche
            Beitrag.
            <v-row v-for="item in preise" :key="item.preis_id">
              <v-col cols="12" md="2">{{ item.preis_alter }} Jahre</v-col>
              <v-col cols="12" md="2">{{ item.preis_value_1 }} €</v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-if="beispiele.length > 0 && tarif.tier_vergleich_nein == 0"
            class="pt-4"
          >
            <v-row v-for="item in beispiele" :key="item.bei_id">
              <v-col cols="12" md="8"
                ><b>{{ item.bei_title }}</b> <br />{{ item.bei_text }}
              </v-col>
              <v-col cols="12" md="2"
                ><b>{{ item.bei_rechnung }} €</b>
                <br />
                <span class="caption">Tierarzt-Rechnung</span>
              </v-col>
              <v-col cols="12" md="2"
                ><b class="green--text">{{ item.bei_erstattung }} €</b>

                <br />
                <span class="caption"
                  >Erstattung durch {{ tarif.tier_title }}</span
                ></v-col
              >
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="avb.length > 0 && tarif.tier_vergleich_nein == 0">
            <ul>
              <li v-for="item in avb" :key="item.doc_id">
                <a
                  :href="`/dist/tarif/${item.doc_dateiname}`"
                  target="_blank"
                  >{{ item.doc_text }}</a
                >
              </li>
            </ul>
          </v-tab-item>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="3" xs="12"
        ><rechner-start-box />

        <v-card outlined class="mt-8" v-if="tarif.un_abschluss_url != ''">
          <v-card-text
            ><h2>Online-Abschluss</h2>
            <p>
              Hier können Sie die <b>{{ tarif.tier_title }}</b> sofort online
              beantragen.
            </p>

            <v-btn
              class="nocaps mb-4"
              style="background-color: #d84315; color: #fff"
            >
              <a
                v-if="tarif.un_abschluss_url != ''"
                @click="saveAntragClickend()"
                :href="tarif.un_abschluss_url"
                target="_blank"
                style="color: #fff"
                >jetzt online beantragen
              </a></v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showRechenweg" width="999">
      <v-card class="pa-4">
        <index-rechenweg :tarif="tarif" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import fieldMixin from "./../mixins/fieldMixin";
  import ButtonTip from "./../base/helper/ButtonTip";
  import RechnerStartBox from "./../base/helper/RechnerStartBox";
  import SpezialPop from "./../base/helper/SpezialPop";
  import IndexRechenweg from "./IndexRechenweg.vue";
  import baseMixin from "./../mixins/baseMixin";
  export default {
    name: "beschreibung-gross",
    components: {
      ButtonTip,
      SpezialPop,
      IndexRechenweg,
      RechnerStartBox,
    },
    mixins: [fieldMixin, baseMixin],
    data: function() {
      return {
        showRechenweg: false,
        updateSeo: {
          seo_type: 1,
          seo_object_id: 0,
          seo_title: "",
        },
        addBeispiel: false,
        testberichte: false,
        showInsertSuccess: false,
        showUpload: false,
        isAdmin: false,
        tarif: false,
        selectpreise: {
          type: "alle",
          id: 0,
          number: "array",
        },
        avb: false,
        preise: false,
        origin: false,
        table: "hund",
        selectupdate: {
          type: "einzel",
          id: 0,
          number: "one",
        },
        newFile: {
          userfile: false,
          doc_tarif_id: 0,
          doc_text: "",
          doc_status: "0",
          doc_typ: "3",
        },
        beispiele: false,
        insertbeispiel: {
          bei_created_date: "",
          bei_title: "",
          bei_text: "",
          bei_hund_id: 1,
          bei_rechnung: 0,
          bei_erstattung: 0,
          bei_tarif_id: 0,
        },
        benchmark: false,
        inserttest: {
          test_tarif_id: 0,
          test_value: "",
          test_type: 1,
          test_year: 2021,
        },
        selecturl: {
          type: "einzel",
          id: this.$route.params.id,
          number: "one",
        },
        select: {
          type: "einzel",
          id: 0,
          number: "one",
        },
        selectantrag: {
          action: "saveclickantrag",
          tier_id: 0,
        },
        showTbFields: {
          tier_title: {
            field: "tier_title",
            table: "hund",
          },
          tier_abrechnung_direkt: {
            field: "tier_abrechnung_direkt",
            table: "hund",
          },
          tier_intern_ersattvoraussetzung: {
            field: "tier_intern_ersattvoraussetzung",
            table: "hund",
          },
        },
      };
    },
    created() {
      this.getTarife();
      this.origin = this.hund;
    },

    methods: {
      saveAntragClickend() {
        this.$store
          .dispatch("apiTarifPost", this.selectantrag)
          .then(() => {})
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getTarifBenchmark() {
        this.$log.debug("bench");
        this.$store
          .dispatch("getTarifBenchmark", this.select)
          .then((resp) => {
            if (resp.data.benchmark) {
              this.benchmark = resp.data.benchmark;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_type = 1;
          this.updateSeo.seo_object_id = this.tarif.tier_id;
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },
      getAvb() {
        this.$log.debug("getAvB", this.tarif.tier_id);
        this.$store.dispatch("getAvb", this.tarif.tier_id).then((resp) => {
          if (resp.data.avb) {
            this.avb = resp.data.avb;
          }
        });
      },

      setTitle() {
        if (this.tarif.tier_id > 0) {
          this.$log.debug("title");

          document.title =
            this.tarif.tier_title +
            " Krankenversicherung Hund " +
            this.tarif.tier_wert +
            " % von 100 % im Eisbaum-Test";
          let metadesc =
            this.tarif.tier_title +
            " Krankenversicherung Hund " +
            this.tarif.tier_wert +
            " % von 100 % im Eisbaum-Test (5-Jahres Realwert-Methode)";
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", metadesc);
        }
      },
      getPreise() {
        this.selectpreise.id = this.tarif.tier_id;
        this.$store
          .dispatch("getPreise", this.selectpreise)
          .then((resp) => {
            if (resp.data.preise) {
              this.preise = resp.data.preise;
            }
            //  document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },

      getBeispiele() {
        this.$store
          .dispatch("getBeispiele", this.selectpreise)
          .then((resp) => {
            if (resp.data.beispiele) {
              this.beispiele = resp.data.beispiele;
            }
            //  document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getTestberichte() {
        this.$store
          .dispatch("getTestberichte", this.selectpreise)
          .then((resp) => {
            if (resp.data.testberichte) {
              this.testberichte = resp.data.testberichte;
            }
            // document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },

      getTarife() {
        this.$store
          .dispatch("getTarifeUrl", this.selecturl)
          .then((resp) => {
            if (resp.data.tarife) {
              this.tarif = resp.data.tarife;
              this.select.id = this.tarif.tier_id;
              this.selectantrag.id = this.tarif.tier_id;
              this.selectantrag.name = this.tarif.tier_title;
              this.setTitle();
              this.saveReferrer();
              if (this.tarif.tier_vergleich_nein == 0) {
                this.getTestberichte();
                this.getPreise();
                this.getBeispiele();
                this.getAvb();
                this.getTarifBenchmark();
              }

              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
            document.dispatchEvent(new Event("x-app-rendered"));
          });
      },
    },
  };
</script>
